import { useContext } from "react";
import { DidContext } from "../../context/index.jsx";
import Button from "../Button/index.jsx";
import CopyToClipboard from "../CopyToClipboard";

const InvitationManualProcess = () => {
  const context = useContext(DidContext);

  return (
    <div className="InvitationManualProcess">
      <h2>Process invitation (manual process): </h2>
      <Button
        onClick={context.processInvitation}
        text={"Process & List"}
        disabled={
          context.oobContentData && context.verifiableDid.did ? false : true
        }
      />
      <CopyToClipboard
        textarea={false}
        disabled={true}
        inputValue={
          context.manualProcessData.did
            ? context.manualProcessData.did
            : "Select holder DID from My Dids page to continue"
        }
      />

      <CopyToClipboard
        inputValue={context.oobContentData}
        textarea={true}
        disabled={true}
      />
      <p>Response</p>
      <CopyToClipboard
        inputValue={context.processResponse}
        textarea={false}
        disabled={true}
      ></CopyToClipboard>
    </div>
  );
};

export default InvitationManualProcess;
