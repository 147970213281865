// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonContainer {
  display: flex;
}

.button {
  outline: none;
  border: none;

  padding: 0.8vw 1.5vw;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1.1vw;
  cursor: pointer;
  transition: 0.3s;
  will-change: background-color;
}

.button.green {
  background-color: #0abb89;
  border: 2px solid #0abb89;

  color: #ffffff;
}

.button.green:hover {
  background-color: #0acd96;
}

.button.green:active {
  background-color: #0abb89;
}

.button.green.darkMode:disabled {
  background-color: rgb(32, 76, 64);
  color: #717171;
  cursor: auto;
}

.button.green.lightMode:disabled {
  background-color: #69c2a9;
  color: #d5d5d5;
  cursor: auto;
}

.button.orange {
  background-color: transparent;
  border: 2px solid;
  opacity: 0.8;
  transition: 0.3s;
  will-change: opacity;
}

.button.orange:hover {
  opacity: 1;
}

.button.orange:active {
  opacity: 1;
}

.button.orange.darkMode {
  background-color: transparent;
  color: #a42a24;
  color: #d83027;
}

.button.orange.lightMode {
  color: #d83027;
}

.icon-container {
  margin-left: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;;EAEZ,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;;EAEzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,cAAc;EACd,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".ButtonContainer {\n  display: flex;\n}\n\n.button {\n  outline: none;\n  border: none;\n\n  padding: 0.8vw 1.5vw;\n  border-radius: 4px;\n  font-weight: 500;\n  font-size: 1.1vw;\n  cursor: pointer;\n  transition: 0.3s;\n  will-change: background-color;\n}\n\n.button.green {\n  background-color: #0abb89;\n  border: 2px solid #0abb89;\n\n  color: #ffffff;\n}\n\n.button.green:hover {\n  background-color: #0acd96;\n}\n\n.button.green:active {\n  background-color: #0abb89;\n}\n\n.button.green.darkMode:disabled {\n  background-color: rgb(32, 76, 64);\n  color: #717171;\n  cursor: auto;\n}\n\n.button.green.lightMode:disabled {\n  background-color: #69c2a9;\n  color: #d5d5d5;\n  cursor: auto;\n}\n\n.button.orange {\n  background-color: transparent;\n  border: 2px solid;\n  opacity: 0.8;\n  transition: 0.3s;\n  will-change: opacity;\n}\n\n.button.orange:hover {\n  opacity: 1;\n}\n\n.button.orange:active {\n  opacity: 1;\n}\n\n.button.orange.darkMode {\n  background-color: transparent;\n  color: #a42a24;\n  color: #d83027;\n}\n\n.button.orange.lightMode {\n  color: #d83027;\n}\n\n.icon-container {\n  margin-left: 1vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
