// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonContainer {
  display: flex;
}

.button {
  outline: none;
  border: none;
  background-color: #0abb89;
  padding: 0.8vw 1.5vw;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.1vw;
  cursor: pointer;
  transition: 0.3s;
  will-change: background-color;
}

.button:hover {
  background-color: #0acd96;
}

.button:active {
  background-color: #0abb89;
}

.button.darkMode:disabled {
  background-color: rgb(32, 76, 64);
  color: #717171;
  cursor: auto;
}

.button.lightMode:disabled {
  background-color: #69c2a9;
  color: #d5d5d5;
  cursor: auto;
}

.icon-container {
  margin-left: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,cAAc;EACd,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".ButtonContainer {\n  display: flex;\n}\n\n.button {\n  outline: none;\n  border: none;\n  background-color: #0abb89;\n  padding: 0.8vw 1.5vw;\n  border-radius: 4px;\n  color: #ffffff;\n  font-weight: 500;\n  font-size: 1.1vw;\n  cursor: pointer;\n  transition: 0.3s;\n  will-change: background-color;\n}\n\n.button:hover {\n  background-color: #0acd96;\n}\n\n.button:active {\n  background-color: #0abb89;\n}\n\n.button.darkMode:disabled {\n  background-color: rgb(32, 76, 64);\n  color: #717171;\n  cursor: auto;\n}\n\n.button.lightMode:disabled {\n  background-color: #69c2a9;\n  color: #d5d5d5;\n  cursor: auto;\n}\n\n.icon-container {\n  margin-left: 1vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
