import { useEffect, useState } from "react";

function useLocalStorage(itemName, initialValue) {
  const [dids, setDids] = useState(initialValue);
  const [sincronizedDids, setSincronizedDids] = useState(true);

  useEffect(() => {
    try {
      const localStorageDids = localStorage.getItem(itemName);
      let parsedDids;
      if (!localStorageDids) {
        localStorage.setItem(itemName, JSON.stringify(initialValue));
        parsedDids = initialValue;
      } else {
        parsedDids = JSON.parse(localStorageDids);
      }
      setDids(parsedDids);
      setSincronizedDids(true);
    } catch (error) {
      console.log(error);
    }
  }, [sincronizedDids, itemName]);

  const saveDids = (newItem) => {
    localStorage.setItem(itemName, JSON.stringify(newItem));
    setDids(newItem);
  };

  const deleteDids = () => {
    localStorage.removeItem(itemName);
    setDids({
      issuers: [],
      holders: [],
    });
  };

  const sincronizeItem = () => {
    setSincronizedDids(false);
  };

  return { dids, saveDids, sincronizeItem, deleteDids };
}

export { useLocalStorage };
