import { useContext } from "react";
import Button from "../Button";
import CopyToClipboard from "../CopyToClipboard";
import { DidContext } from "../../context";

const GetCredentials = () => {
  const context = useContext(DidContext);

  return (
    <div className="GetCredentials">
      <h2>Get credentials</h2>
      <CopyToClipboard
        inputValue={
          context.verifiableDid.did
            ? context.verifiableDid.did
            : "Select holder DID"
        }
        textarea={false}
        disabled={true}
      />
      <Button
        onClick={context.getCredentials}
        text={"Get Credentials"}
        disabled={!context.verifiableDid.did}
      />
      <CopyToClipboard
        inputValue={context.credentials}
        textarea={true}
        disabled={true}
      />
    </div>
  );
};

export default GetCredentials;
