import CreateDID from "../components/CreateDID";
import MyDIDs from "../components/MyDIDs";
import GetDocument from "../components/GetDocument";
import Modal from "../components/Modal";
import { useContext } from "react";
import { DidContext } from "../context";

const DID = () => {
  const context = useContext(DidContext);
  return (
    <>
      <MyDIDs />
      {context.openCreate && (
        <Modal>
          <CreateDID />
        </Modal>
      )}
      {context.openDocument && (
        <Modal>
          <GetDocument />
        </Modal>
      )}
    </>
  );
};

export default DID;
