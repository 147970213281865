import { useContext, useState } from "react";
import { DidContext } from "../../context/index.jsx";
import CopyToClipboard from "../CopyToClipboard";
import InvitationForm from "../InvitationForm/index.jsx";
import invitation from "../../utils/invitation.js";

const InvitationMessage = () => {
  const context = useContext(DidContext);
  const [formData, setFormData] = useState({
    did: context.issuerDid.did,
    issuer: context.issuerDid.ref,
    name: "",
    lastName: "",
    birth: "",
    value1: "",
    value2: "",
    value3: "",
  });

  const generate = () => {
    const data = invitation(
      context.issuerDid.vcType,
      formData.did,
      formData.issuer,
      formData.name,
      formData.lastName,
      formData.birth,
      formData.value1,
      formData.value2,
      formData.value3
    );
    context.generateInvitation(data);
  };

  return (
    <div>
      <h2>Invitation message with QR Code</h2>
      <CopyToClipboard
        inputValue={
          context.issuerDid.did
            ? context.issuerDid.did
            : "Select issuer DID from My Dids page to continue"
        }
        textarea={false}
        disabled={true}
      />

      <InvitationForm
        generate={generate}
        formData={formData}
        setFormData={setFormData}
      />
      <p>Invitation ID:</p>
      <CopyToClipboard
        inputValue={context.invitationId}
        textarea={false}
        disabled={true}
      />
      <p>oobContentData: </p>
      <CopyToClipboard
        inputValue={context.oobContentData}
        textarea={true}
        disabled={true}
      />
    </div>
  );
};

export default InvitationMessage;
