// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh;
  max-height: 100vh;
}

.App.darkMode {
  color: #f9f9f9;
  background: #222222;
}

.App.lightMode {
  color: #1e1f1f;
  background: #ffffff;
}

h2 {
  display: flex;
  align-items: center;
  margin: 1.5vw 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".App {\n  min-height: 100vh;\n  max-height: 100vh;\n}\n\n.App.darkMode {\n  color: #f9f9f9;\n  background: #222222;\n}\n\n.App.lightMode {\n  color: #1e1f1f;\n  background: #ffffff;\n}\n\nh2 {\n  display: flex;\n  align-items: center;\n  margin: 1.5vw 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
