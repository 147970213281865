import { useContext, useState } from "react";
import { DidContext } from "../../context";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import Loader from "../Loader";
import "./Credential.css";

const Credential = ({ credential }) => {
  const context = useContext(DidContext);

  const [loading, setLoading] = useState(false);

  const selectVc = (param) => {
    setLoading(true);

    const verificationVc = JSON.parse(context.credentials).find(
      (credential) => credential.id === param
    );
    context.verifyCredential(verificationVc);
  };
  return (
    <div className={`CredentialContainer `}>
      {loading &&
        (credential.verified ? (
          <div className="Credential-check-container success">
            <CheckBadgeIcon className="Credential-check" />
          </div>
        ) : (
          <div className="Credential-check-container loading">
            <Loader type={"circular"} loaderWidth={"2.5vw"} />
          </div>
        ))}
      {credential.verified ? (
        <p className="Credential-checkText verified">Verified</p>
      ) : loading ? (
        <p className="Credential-checkText verifying">Veryfing</p>
      ) : (
        <p
          onClick={() => selectVc(credential.vc.data.data.id)}
          className="Credential-checkText verify"
        >
          Verify
        </p>
      )}
      <div
        style={{
          backgroundImage: `url(${credential.vc.data.styles.hero.uri})`,
          color: `${credential.vc.data.styles.text.color}`,
          backgroundSize: "190%",
          backgroundRepeat: "no-repeat",
        }}
        className={`Credential`}
      >
        <h6>
          {" "}
          <figure
            style={{
              background: ` ${credential.vc.data.styles.background.color}`,
            }}
            className={`Credential-thumbnail`}
          >
            <img
              src={credential.vc.data.styles.thumbnail.uri}
              alt="Thumbnail"
            ></img>
          </figure>
          {credential.vc.data.data.issuer.name}
        </h6>
        <div>
          <p>
            {credential.vc.data.data.credentialSubject.name}{" "}
            {credential.vc.data.data.credentialSubject.lastName}
          </p>
          <p></p>
          <p>{credential.vc.data.data.credentialSubject.birth}</p>
        </div>
      </div>
    </div>
  );
};

export default Credential;
