import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import "./Modal.css";

const Modal = ({ children }) => {
  const context = useContext(DidContext);
  const [show, setShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 100);
  }, []);

  return (
    <div
      className={`Modal ${context.darkMode ? "darkMode" : "lightMode"} ${
        show ? "hide" : "show"
      }`}
    >
      <div className="modal-container">{children}</div>
    </div>
  );
};

export default Modal;
