import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "../CopyToClipboard";
import "./Statebar.css";

const StateBar = () => {
  const context = useContext(DidContext);
  const [focus, setFocus] = useState("issuer");
  const [data, setData] = useState({
    did: "No DID assigned. Select or create one",
    ref: "",
  });
  useEffect(() => {
    if (focus === "issuer") {
      context.issuerDid.did
        ? setData({ did: context.issuerDid.did, ref: context.issuerDid.ref })
        : setData({ did: "No DID assigned. Select or create one", ref: "" });
    } else if (focus === "holder") {
      context.verifiableDid.did
        ? setData({
            did: context.verifiableDid.did,
            ref: context.verifiableDid.ref,
          })
        : setData({ did: "No DID assigned. Select or create one", ref: "" });
    }
  }, [focus, context.issuerDid.did, context.verifiableDid.did]);

  return (
    <div className={`StateBar ${context.theme} `}>
      <div className="statebar-role">
        <div
          onClick={() => setFocus("issuer")}
          className={`statebar-role-button ${focus === "issuer" && "active"}`}
        >
          <div className="status">
            <div
              className={`status-check ${
                context.issuerDid.created
                  ? "status-check-true"
                  : "status-check-false"
              } `}
            ></div>
          </div>
          Issuer
        </div>
        <div
          onClick={() => setFocus("holder")}
          className={`statebar-role-button ${focus === "holder" && "active"}`}
        >
          <div className="status">
            <div
              className={`status-check ${
                context.verifiableDid.created
                  ? "status-check-true"
                  : "status-check-false"
              } `}
            ></div>
          </div>
          Holder
        </div>
      </div>
      <div className="statebar-container">
        {" "}
        <CopyToClipboard
          inputValue={data.did}
          textarea={false}
          disabled={true}
        ></CopyToClipboard>
      </div>
      <div className="ref-container">
        {" "}
        <span>Reference:</span>
        <p>{data.ref}</p>
      </div>
      <div
        className="theme"
        onClick={() => context.setDarkMode(!context.darkMode)}
      >
        {context.darkMode ? (
          <SunIcon className="sun-icon" />
        ) : (
          <MoonIcon className="moon-icon" />
        )}
      </div>
    </div>
  );
};

export default StateBar;
