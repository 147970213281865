import { useContext } from "react";
import { DidContext } from "../../context";
import Button from "../Button";
import CopyToClipboard from "../CopyToClipboard";

const VerifyCredentials = () => {
  const context = useContext(DidContext);

  return (
    <div className="VerifyCredentials">
      <h2>Verify Credential</h2>
      <CopyToClipboard
        textarea={false}
        inputValue={context.vc ? context.vc.vc.issuer.name : ""}
        disabled={true}
      />
      <CopyToClipboard
        textarea={true}
        inputValue={context.render ? JSON.stringify(context.vc) : ""}
        disabled={true}
      />
      <Button
        text={"Verify Credential"}
        onClick={context.verifyCredential}
        disabled={!context.vc}
      />
      <CopyToClipboard
        textarea={false}
        inputValue={context.verification}
        disabled={true}
      />
    </div>
  );
};

export default VerifyCredentials;
