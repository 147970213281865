import { useState } from "react";
import GetCredentials from "../components/GetCredentials";
import VerifyCredentials from "../components/VerifyCredentials";
import RenderCredentials from "../components/RenderCredentials";

const Credentials = () => {
  return (
    <div className="Credentials">
      <GetCredentials />
      <RenderCredentials />
      <VerifyCredentials />
    </div>
  );
};

export default Credentials;
