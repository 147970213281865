// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  width: 15%;
  height: calc(100vh - 6vw);
  top: 5vw;
  padding-top: 1vw;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.Header.darkMode {
  border-right: 1px solid #555555;
  background: #333333;
  color: #f9f9f9;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.Header.lightMode {
  border-right: 1px solid #c8c8c8;
  background: #f9f9f9;
  color: #1e1f1f;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.09);
}

.Header.darkMode .nav a {
  color: #f9f9f9;
}

.Header.lightMode .nav a {
  color: #1e1f1f;
}

.Header.darkMode .nav a.active {
  background-color: #444444;
}

.Header.lightMode .nav a.active {
  background-color: #e9e9e9;
}

.Header.darkMode .nav a:hover {
  background-color: #444444;
}

.Header.lightMode .nav a:hover {
  background-color: #e9e9e9;
}

.nav {
  display: flex;
}

.nav a {
  width: 100%;
  padding: 0.5vw 2vw;
  font-size: 1vw;
  transition: 0.3s;
  will-change: background-color;
}

.logo-container {
  width: 80%;
  left: 10%;
  right: 5%;
  bottom: 3vw;
  position: absolute;
}

.logo-container img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,yBAAyB;EACzB,QAAQ;EACR,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,cAAc;EACd,+CAA+C;AACjD;AACA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,cAAc;EACd,gDAAgD;AAClD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,SAAS;EACT,SAAS;EACT,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".Header {\n  width: 15%;\n  height: calc(100vh - 6vw);\n  top: 5vw;\n  padding-top: 1vw;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  z-index: 4;\n}\n\n.Header.darkMode {\n  border-right: 1px solid #555555;\n  background: #333333;\n  color: #f9f9f9;\n  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.4);\n}\n.Header.lightMode {\n  border-right: 1px solid #c8c8c8;\n  background: #f9f9f9;\n  color: #1e1f1f;\n  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.09);\n}\n\n.Header.darkMode .nav a {\n  color: #f9f9f9;\n}\n\n.Header.lightMode .nav a {\n  color: #1e1f1f;\n}\n\n.Header.darkMode .nav a.active {\n  background-color: #444444;\n}\n\n.Header.lightMode .nav a.active {\n  background-color: #e9e9e9;\n}\n\n.Header.darkMode .nav a:hover {\n  background-color: #444444;\n}\n\n.Header.lightMode .nav a:hover {\n  background-color: #e9e9e9;\n}\n\n.nav {\n  display: flex;\n}\n\n.nav a {\n  width: 100%;\n  padding: 0.5vw 2vw;\n  font-size: 1vw;\n  transition: 0.3s;\n  will-change: background-color;\n}\n\n.logo-container {\n  width: 80%;\n  left: 10%;\n  right: 5%;\n  bottom: 3vw;\n  position: absolute;\n}\n\n.logo-container img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
