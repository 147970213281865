import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "../utils/useLocalStorage";

export const DidContext = createContext();

export const DidProvider = ({ children }) => {
  // Data
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const getRequestUrl = (param) => {
    return `${baseUrl}${param}`;
  };

  // Theme
  const [darkMode, setDarkMode] = useState(true);
  const theme = darkMode ? "darkMode" : "lightMode";

  // Operational Dids
  const [issuerDid, setIssuerDid] = useState("");
  const [verifiableDid, setVerifiableDid] = useState("");
  const [didDocument, setDidDocument] = useState("");

  // Get DID list
  const { dids, saveDids, deleteDids } = useLocalStorage("MyDids", {
    issuers: [],
    holders: [],
  });

  // Create DID
  const [openCreate, setOpenCreate] = useState(false);
  const [onCreate, setOnCreate] = useState(false);
  const [createNewDid, setCreateNewDid] = useState({
    did: "",
    processing: false,
    message: "",
  });

  async function createDid(data) {
    const url = getRequestUrl(`/dids/quarkid`);

    const headers = {
      "Content-Type": "application/json",
    };
    const body = {
      websocket: "https://sandbox-ssi-ws.extrimian.com",
      dwn: "",
      webhookURL: "",
      verificationRulesEndpoint: "",
      didMethod: "did:quarkid",
    };

    setCreateNewDid({
      did: "",
      processing: true,
      message: "Creating DID",
    });
    try {
      const response = await axios.put(url, body, { headers });
      setCreateNewDid({
        did: response.data.did,
        processing: true,
        message: "DID was created succesfuly",
      });

      addDids(data.role, {
        did: response.data.did,
        ref: data.ref,
        vcType: data.vcType,
        created: false,
      });
    } catch (error) {
      console.error("Error in API call:", error);
      setCreateNewDid({
        did: "failed",
        processing: false,
        message: error.message,
      });
    }
  }

  // Save DID
  const addDids = (role, didToAdd) => {
    const newDids = { ...dids };
    if (role === "issuers") {
      newDids.issuers.push(didToAdd);
    } else if (role === "holders") {
      newDids.holders.push(didToAdd);
    }

    saveDids(newDids);
  };

  // Delete DIDs
  const removeDids = () => {
    deleteDids();
  };

  // Resolve DID Document
  async function getDidResult(value, role, status) {
    const url = getRequestUrl(`/dids/quarkid/${value}`);

    while (!status) {
      try {
        const response = await axios(url);
        if (response.data.id) {
          createdDid(value, role);
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 30000)); // Espera 15 segundos
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 30000)); // Espera 15 segundos
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Get DID Document
  const [openDocument, setOpenDocument] = useState(false);

  async function getDidDocument(did, ref) {
    const url = getRequestUrl(`/dids/quarkid/${did}`);
    try {
      const response = await axios(url);
      setDidDocument({
        reference: ref,
        document: JSON.stringify(response.data),
      });
    } catch (error) {
      setDidDocument(error.response);
    }
  }

  // Add DID to saved list
  const createdDid = (value, role) => {
    const newDids = { ...dids };
    let checkedDid;
    if (role === "issuers") {
      checkedDid = newDids.issuers.find((did) => did.did === value);
    } else if (role === "holders") {
      checkedDid = newDids.holders.find((did) => did.did === value);
    }
    checkedDid.created = true;

    saveDids(newDids);
  };

  // Check DID status
  useEffect(() => {
    const pendingIssuerCreations = dids.issuers.filter((did) => !did.created);
    const pendingHoldersCreations = dids.holders.filter((did) => !did.created);
    pendingIssuerCreations.forEach((did) =>
      getDidResult(did.did, "issuers", did.created)
    );
    pendingHoldersCreations.forEach((did) =>
      getDidResult(did.did, "holders", did.created)
    );
  }, [dids]);

  // Invitation message
  const [oobContentData, setOobContentData] = useState("");
  const [showQr, setshowQr] = useState(false);
  const [invitationId, setInvitationId] = useState("");

  async function generateInvitation(values) {
    const url = getRequestUrl(`/credentialsbbs/wacioob`);

    const headers = {
      "Content-Type": "application/json",
    };
    const data = values;

    try {
      const response = await axios.put(url, data, { headers });
      setOobContentData(response.data.oobContentData);
      setInvitationId(response.data.invitationId);
      setshowQr(true);
    } catch (error) {
      console.error("Error in API call:", error);
      throw error;
    }
  }

  useEffect(() => {
    setshowQr(false);
    setOobContentData("");
    setInvitationId("");
  }, [issuerDid]);

  // Invitation Manual Process
  const [processResponse, setProcessResponse] = useState("");
  const [manualProcessData, setManualProcessData] = useState({
    did: "",
    message: "",
  });

  // Set data changes
  useEffect(() => {
    setManualProcessData({
      did: verifiableDid.did,
      message: oobContentData,
    });
  }, [oobContentData, verifiableDid.did]);

  // Process invitation

  async function processInvitation() {
    const url = getRequestUrl(`/credentialsbbs/waci`);

    const headers = {
      "Content-Type": "application/json",
    };
    const data = manualProcessData;

    try {
      const response = await axios.put(url, data, { headers });
      setProcessResponse(
        JSON.stringify({
          status: response.status,
          statusText: response.statusText,
        })
      );
    } catch (error) {
      if (error.response && error.response.status === 500) {
      } else {
        console.error("Error no esperado:", error);
      }
    }
  }

  // Get credentials
  const [credentials, setCredentials] = useState([]);

  async function getCredentials() {
    const url = getRequestUrl(
      `/credentialsbbs?did=${verifiableDid.did.replaceAll(":", "%3A")}`
    );

    while (true) {
      try {
        const response = await axios(url);
        setCredentials(JSON.stringify(response.data));

        break;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 15000)); // Espera 15 segundos
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Render credentials
  const [render, setRender] = useState(undefined);
  const [vc, setVc] = useState("");

  async function renderCredential() {
    const url = getRequestUrl(
      `/credentialsbbs/render?did=${verifiableDid.did.replaceAll(":", "%3A")}`
    );

    setRender(undefined);

    while (true) {
      try {
        const response = await axios(url);
        setRender(response.data);

        break;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 15000)); // Espera 15 segundos
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Verify
  const [verification, setVerification] = useState("");

  async function verifyCredential() {
    const url = getRequestUrl(`/credentialsbbs/verifier`);

    const headers = {
      "Content-Type": "application/json",
    };
    const data = { vc: vc.vc };

    try {
      const response = await axios.put(url, data, { headers });

      setVerification(JSON.stringify(response.data));
    } catch (error) {
      if (error.response && error.response.status === 500) {
      } else {
        console.error("Error no esperado:", error);
      }
    }
  }

  return (
    <DidContext.Provider
      value={{
        darkMode,
        theme,
        setDarkMode,
        issuerDid,
        setIssuerDid,
        verifiableDid,
        setVerifiableDid,
        didDocument,
        setDidDocument,
        dids,
        saveDids,
        openCreate,
        setOpenCreate,
        createDid,
        createNewDid,
        setCreateNewDid,
        getDidDocument,
        openDocument,
        setOpenDocument,
        oobContentData,
        setOobContentData,
        showQr,
        invitationId,
        generateInvitation,
        onCreate,
        setOnCreate,
        processResponse,
        manualProcessData,
        processInvitation,
        credentials,
        getCredentials,
        render,
        renderCredential,
        vc,
        setVc,
        verification,
        verifyCredential,
        removeDids,
      }}
    >
      {children}
    </DidContext.Provider>
  );
};
