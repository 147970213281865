import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import Button from "../Button";
import QRCode from "react-qr-code";
import "./InvitationForm.css";

const InvitationForm = ({ generate, formData, setFormData }) => {
  const context = useContext(DidContext);
  const [placeholders, setPlaceholders] = useState({});
  const [cardIcon, setcardIcon] = useState(undefined);
  const [cardType, setCardType] = useState("cardTypeNone");
  // Load form type
  useEffect(() => {
    switch (context.issuerDid.vcType) {
      default:
        setcardIcon(undefined);
        setCardType("cardTypeNone");
        setPlaceholders({
          name: "",
          lastName: "",
          birth: "",
          value1: "",
          value2: "",
          value3: "",
        });
        break;
      case "citizen":
        setcardIcon(
          "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678086-institution-512.png"
        );

        setCardType("citizen");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Address",
          value2: "Nationality",
          value3: "Birth Place",
        });
        break;
      case "university":
        setcardIcon(
          "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png"
        );
        setCardType("university");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Institution",
          value2: "Career",
          value3: "ID Number",
        });
        break;
      case "bank":
        setcardIcon(
          "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_institution-256.png"
        );
        setCardType("bank");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Address",
          value2: "Nationality",
          value3: "Birth Place",
        });
    }
  }, [context.issuerDid.vcType]);

  // Input listener
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="get-invitation-container">
      <form
        action=""
        onSubmit={(e) => e.preventDefault()}
        className={`invitation-form ${context.theme}`}
      >
        <input
          type="text"
          name="issuer"
          value={formData.issuer !== "" ? formData.issuer : ""}
          disabled
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="name"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.name : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.lastName : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="birth"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.birth : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value1"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.value1 : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value2"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.value2 : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value3"
          disabled={!formData.issuer}
          placeholder={formData.issuer !== "" ? placeholders.value3 : ""}
          onChange={handleInputChange}
        />
        <Button
          onClick={generate}
          text={"Generate Invitation"}
          disabled={!formData.issuer}
        />
      </form>
      <div className={`presentation-container ${cardType}`}>
        {" "}
        {cardIcon ? (
          <div className={`presentation-logo ${cardType}`}>
            <img src={cardIcon} alt="logo" />{" "}
          </div>
        ) : (
          <></>
        )}
        <div className="presentation-data">
          <h5>{formData.issuer}</h5>
          <p>
            {placeholders.name} <span>{formData.name}</span>
          </p>
          <p>
            {placeholders.lastName} <span>{formData.lastName}</span>
          </p>
          <p>
            {placeholders.birth} <span>{formData.birth}</span>
          </p>
          <p>
            {placeholders.value1} <span>{formData.value1}</span>
          </p>
          <p>
            {placeholders.value2} <span>{formData.value2}</span>
          </p>
          <p>
            {placeholders.value3} <span>{formData.value3}</span>
          </p>
        </div>
      </div>
      <div className={`qr-container ${context.showQr ? "show" : "hide"}`}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={context.oobContentData}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
};

export default InvitationForm;
