import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "../utils/useLocalStorage";
import invitation from "../utils/invitation.js";

export const DidContext = createContext();

export const DidProvider = ({ children }) => {
  // Data
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const getRequestUrl = (param) => {
    return `${baseUrl}${param}`;
  };

  // Theme
  const [darkMode, setDarkMode] = useState(true);
  const theme = darkMode ? "darkMode" : "lightMode";

  // Operational Dids
  const [issuerDid, setIssuerDid] = useState("");
  const [verifiableDid, setVerifiableDid] = useState("");
  const [credentials, setCredentials] = useState("");
  const [vcsById, setVcsById] = useState([]);

  const [didDocument, setDidDocument] = useState("");
  const { dids, saveDids, deleteDids } = useLocalStorage("MyDids", {
    issuers: [
      {
        did: "did:quarkid:EiANRoiwZzyYpSz3RWhFhuesH2x-hx8hGuFDu-arhbaFRA",
        ref: "Government",
        vcType: "citizen",
        created: true,
        iconUrl:
          "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_institution-256.png",
      },
      {
        did: "did:quarkid:EiBJpk86iip7KiuAukLbNRdLvsSV3tguNlxBuBD3Gw2cRg",
        ref: "Southern University",
        vcType: "university",
        created: true,
        iconUrl:
          "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png",
      },
      {
        did: "did:quarkid:EiAir4zUcBDef-fqPyYQQD3znie-29rNqMGrrrYgRPtCOg",
        ref: "Naturgy",
        vcType: "naturgy",
        created: true,
        iconUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ3K-fYTyHdMIaXGdbsO2YhIzTd8rNrDCDT9S3BM27mGw0LvE4eOaHXBstVF7HVLbrfUQ&usqp=CAU",
      },
      {
        did: "did:quarkid:EiDvoQgdF4OOBm0fNmTJGGgqsIEE9FwUkEVMvYGpG0h3Hg",
        ref: "Santander Bank",
        vcType: "santander",
        created: true,
        iconUrl:
          "https://i.ibb.co/0cZdRcM/bcda8a3eaa78befafba68b851b2cdfc0.png",
      },
    ],
    holders: [],
  });

  // useEffect(() => {
  //   if (verifiableDid.did) {
  //     getCredentials();
  //   }
  // }, [verifiableDid.did]);

  // Create DID
  const [openCreate, setOpenCreate] = useState(false);
  const [onCreate, setOnCreate] = useState(false);
  const [createNewDid, setCreateNewDid] = useState({
    did: "",
    processing: false,
    message: "",
  });

  async function createDid(data) {
    const url = getRequestUrl(`/dids/quarkid`);

    const headers = {
      "Content-Type": "application/json",
    };
    const body = {
      websocket: "https://sandbox-ssi-ws.extrimian.com",
      dwn: "",
      webhookURL: "",
      verificationRulesEndpoint: "",
      didMethod: "did:quarkid",
    };

    setCreateNewDid({
      did: "",
      processing: true,
      message: "Creating DID",
    });
    try {
      const response = await axios.put(url, body, { headers });
      setCreateNewDid({
        did: response.data.did,
        processing: true,
        message: "DID was created succesfuly",
      });

      addDids(data.role, {
        did: response.data.did,
        ref: data.ref,
        vcType: data.vcType,
        created: false,
      });
    } catch (error) {
      console.error("Error in API call:", error);
      setCreateNewDid({
        did: "failed",
        processing: false,
        message: error.message,
      });
    }
  }

  // Save DID
  const addDids = (role, didToAdd) => {
    const newDids = { ...dids };
    if (role === "issuers") {
      newDids.issuers.push(didToAdd);
    } else if (role === "holders") {
      newDids.holders.push(didToAdd);
    }

    saveDids(newDids);
  };

  // Delete DIDs
  const [openDeleteDids, setOpenDeleteDids] = useState(true);
  const removeDids = () => {
    setIssuerDid("");
    setVerifiableDid("");
    deleteDids();
  };

  // Resolve DID Document
  async function getDidResult(value, role, status) {
    const url = getRequestUrl(`/dids/quarkid/${value}`);

    while (!status) {
      try {
        const response = await axios(url);
        if (response.data.id) {
          createdDid(value, role);
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 30000)); // Espera 15 segundos
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 30000)); // Espera 15 segundos
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Get DID Document
  const [openDocument, setOpenDocument] = useState(false);

  async function getDidDocument(did, ref) {
    const url = getRequestUrl(`/dids/quarkid/${did}`);
    try {
      const response = await axios(url);
      setDidDocument({
        reference: ref,
        document: JSON.stringify(response.data),
      });
    } catch (error) {
      setDidDocument(error.response);
    }
  }

  // Add DID to saved list
  const createdDid = (value, role) => {
    const newDids = { ...dids };
    let checkedDid;
    if (role === "issuers") {
      checkedDid = newDids.issuers.find((did) => did.did === value);
    } else if (role === "holders") {
      checkedDid = newDids.holders.find((did) => did.did === value);
    }
    checkedDid.created = true;

    saveDids(newDids);
  };

  // Check DID status
  useEffect(() => {
    const pendingIssuerCreations = dids.issuers.filter((did) => !did.created);
    const pendingHoldersCreations = dids.holders.filter((did) => !did.created);
    pendingIssuerCreations.forEach((did) =>
      getDidResult(did.did, "issuers", did.created)
    );
    pendingHoldersCreations.forEach((did) =>
      getDidResult(did.did, "holders", did.created)
    );
  }, [dids]);

  // Invitation message
  const [oobContentData, setOobContentData] = useState("");
  const [showQr, setshowQr] = useState(false);
  const [invitationError, setInvitationError] = useState("");
  const [invitationId, setInvitationId] = useState("");
  const [formData, setFormData] = useState({
    did: "",
    issuer: "",
    name: "",
    lastName: "",
    birth: "",
    value1: "",
    value2: "",
    value3: "",
  });

  useEffect(() => {
    setFormData({
      did: issuerDid.did,
      issuer: issuerDid.ref,
      name: "",
      lastName: "",
      birth: "",
      value1: "",
      value2: "",
      value3: "",
    });
  }, [issuerDid.did]);

  const getExistingCredential = () => {
    const existingIssuer = vcsById
      .map((vc) => vc.vc.data.data.issuer.name)
      .includes(issuerDid.ref);
    return existingIssuer;
  };

  async function generateInvitation() {
    const existingCredential = getExistingCredential();
    setOobContentData("");
    const data = invitation(
      issuerDid.vcType,
      formData.did,
      formData.issuer,
      formData.name,
      formData.lastName,
      formData.birth,
      formData.value1,
      formData.value2,
      formData.value3
    );
    const url = getRequestUrl(`/credentialsbbs/wacioob`);
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      if (existingCredential) {
        setInvitationError(
          "This holder DID already has a credential from this issuer created "
        );
      } else {
        const response = await axios.put(url, data, { headers });

        setOobContentData(response.data.oobContentData);
        setInvitationId(response.data.invitationId);
        setshowQr(true);
      }
    } catch (error) {
      console.error("Error in API call:", error);
      throw error;
    }
  }

  useEffect(() => {
    setshowQr(false);
    setOobContentData("");
    setInvitationId("");
  }, [issuerDid]);

  // Invitation Manual Process
  const [processResponse, setProcessResponse] = useState("");
  const [manualProcessData, setManualProcessData] = useState({
    did: "",
    message: "",
  });

  // Set data changes
  useEffect(() => {
    setManualProcessData({
      did: verifiableDid.did,
      message: oobContentData,
    });
    setProcessResponse("");
  }, [oobContentData, verifiableDid.did]);

  // Process invitation

  async function processInvitation() {
    setProcessResponse("Creating verifiable credential..");

    const url = getRequestUrl(`/credentialsbbs/waci`);

    const headers = {
      "Content-Type": "application/json",
    };
    const data = manualProcessData;

    try {
      const response = await axios.put(url, data, { headers });

      setTimeout(() => {
        setProcessResponse(
          JSON.stringify({
            status: response.status,
            statusText: response.statusText,
          })
        );
      }, 2500);
    } catch (error) {
      if (error.response && error.response.status === 500) {
      } else {
        console.error("Error no esperado:", error);
      }
    }
  }

  // Get credentials
  useEffect(() => {
    updateCredentials();
  }, [verifiableDid, processResponse]);
  const updateCredentials = () => {
    if (verifiableDid.did) {
      setVcsById([]);
      getCredentials();
    }
  };
  async function getCredentials() {
    const url = getRequestUrl(
      `/credentialsbbs?did=${verifiableDid.did.replaceAll(":", "%3A")}`
    );

    try {
      const response = await axios(url);
      setCredentials(JSON.stringify(response.data));
      return getIds(response.data);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        await new Promise((resolve) => setTimeout(resolve, 15000));
      } else {
        console.error("Error no esperado:", error);
      }
    }
  }

  // Get credential by ID
  const getIds = (vcs) => {
    vcs.forEach((vc) => {
      getCredentialById(vc.id);
    });
  };

  useEffect(() => {}, [vcsById]);

  async function getCredentialById(param) {
    setVcsById([]);
    const url = getRequestUrl(`/credentialsbbs/vcid?vcId=${param}`);

    try {
      const response = await axios(url);
      const credentialObject = {
        vc: response.data,
        verified: false,
      };

      setVcsById((prevVcsById) => [...prevVcsById, credentialObject]);
    } catch (error) {
      console.log("Error fetching credential by ID:", error);
    }
  }

  // Render credentials
  const [render, setRender] = useState(undefined);

  useEffect(() => {
    setRender(undefined);
  }, [verifiableDid]);
  async function renderCredential() {
    const url = getRequestUrl(
      `/credentialsbbs/render?did=${verifiableDid.did.replaceAll(":", "%3A")}`
    );
    setRender(undefined);
    while (true) {
      try {
        const response = await axios(url);
        setRender(response.data);

        break;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          await new Promise((resolve) => setTimeout(resolve, 15000)); // Espera 15 segundos
        } else {
          console.error("Error no esperado:", error);
          break;
        }
      }
    }
  }

  // Verify
  const [verification, setVerification] = useState("");

  const updateVerificationStatus = (id) => {
    setVcsById((prevVcsById) =>
      prevVcsById.map((credential) =>
        credential.vc.data.data.id === id
          ? { ...credential, verified: true }
          : credential
      )
    );
  };

  async function verifyCredential(param) {
    const url = getRequestUrl(`/credentialsbbs/verifier`);
    const headers = {
      "Content-Type": "application/json",
    };
    const data = { vc: param.vc };

    try {
      const response = await axios.put(url, data, { headers });

      setVerification(JSON.stringify(response.data));
      updateVerificationStatus(param.id);
    } catch (error) {
      if (error.response && error.response.status === 500) {
      } else {
        console.error("Error no esperado:", error);
      }
    }
  }

  return (
    <DidContext.Provider
      value={{
        darkMode,
        theme,
        setDarkMode,
        issuerDid,
        setIssuerDid,
        verifiableDid,
        setVerifiableDid,
        didDocument,
        setDidDocument,
        dids,
        saveDids,
        openCreate,
        setOpenCreate,
        createDid,
        createNewDid,
        setCreateNewDid,
        getDidDocument,
        openDocument,
        setOpenDocument,
        openDeleteDids,
        setOpenDeleteDids,
        oobContentData,
        setOobContentData,
        showQr,
        invitationId,
        generateInvitation,
        invitationError,
        onCreate,
        setOnCreate,
        processResponse,
        manualProcessData,
        processInvitation,
        getCredentials,
        credentials,
        vcsById,
        updateCredentials,
        setVcsById,
        render,
        renderCredential,
        verification,
        verifyCredential,
        removeDids,
        setFormData,
        formData,
      }}
    >
      {children}
    </DidContext.Provider>
  );
};
