import { useContext } from "react";
import { DidContext } from "../../context";
import Button from "../Button";
import CopyToClipboard from "../CopyToClipboard";
import "./RenderCredential.css";

const RenderCredentials = () => {
  const context = useContext(DidContext);

  const selectVc = (param) => {
    context.setVc(
      JSON.parse(context.credentials).find(
        (credential) => credential.id === param
      )
    );
  };
  return (
    <div className="Render">
      <h2>Render credentials</h2>
      <Button
        text={"Render Credential"}
        onClick={context.renderCredential}
        disabled={!context.verifiableDid}
      />
      <CopyToClipboard
        textarea={true}
        inputValue={context.render ? JSON.stringify(context.render) : ""}
        disabled={true}
      />
      {/* {render ? :} */}
      <div className="renderVc-container">
        {context.render &&
          context.render.map((credential) => (
            <div
              className={`renderVc ${
                context.vc.id === credential.id ? "active" : "inactive"
              }`}
              onClick={() => selectVc(credential.data.id)}
              key={credential.data.id}
            >
              <h6>{credential.data.issuer.name}</h6>
              <div>
                <p>
                  {credential.data.credentialSubject.name}{" "}
                  {credential.data.credentialSubject.lastName}
                </p>
                <p></p>
                <p>{credential.data.credentialSubject.birth}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RenderCredentials;
