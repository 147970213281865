import InvitationManualProcess from "../components/InvitationManualProcess";
import InvitationMessage from "../components/InvitationMessage";

const Invitation = () => {
  return (
    <div className="Invitation">
      <InvitationMessage />
      <hr></hr>
      <InvitationManualProcess />
    </div>
  );
};

export default Invitation;
