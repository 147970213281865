const generateRandomId = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

const invitation = (
  vcType,
  did,
  issuer,
  name,
  lastName,
  birth,
  value1,
  value2,
  value3
) => {
  let data;
  const id = generateRandomId(9);
  const citizenData = {
    did: did,
    oneTimeUse: false,
    vc: {
      "@context": [
        "https://www.w3.org/2018/credentials/v1",
        "https://w3id.org/security/bbs/v1",
        {
          name: "https://schema.org/Text",
          lastName: "https://schema.org/Text",
          birth: "https://schema.org/Text",
          address: "https://schema.org/Text",
          nationality: "https://schema.org/Text",
          birthPlace: "https://schema.org/Text",
        },
      ],
      id: id,
      type: ["VerifiableCredential", "CitizenCredential"],
      issuer: {
        id: did,
        name: issuer,
      },
      issuanceDate: "01/10/2024",
      credentialSubject: {
        name: name,
        lastName: lastName,
        birth: birth,
        address: value1,
        nationality: value2,
        birthPlace: value3,
      },
    },
    outputDescriptor: {
      id: "citizen_credential_output",
      schema: "citizen_credential_output",
      display: {
        title: {
          text: issuer,
        },
        subtitle: {
          text: "Citizen Card",
        },
        description: {
          text: `Citizen credential provided by ${issuer}`,
        },
        properties: [
          {
            path: ["$.credentialSubject.name"],
            fallback: "-",
            label: "Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.lastName"],
            fallback: "-",
            label: "Last Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.birth"],
            fallback: "-",
            label: "Birth date",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.address"],
            fallback: "-",
            label: "Address",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.nationality"],
            fallback: "-",
            label: "Nationality",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.birthPlace"],
            fallback: "-",
            label: "Birth Place",
            schema: {
              type: "string",
            },
          },
        ],
      },
      styles: {
        background: {
          color: "#c7f1f1",
        },
        thumbnail: {
          uri: "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_building-512.png",
          alt: "titulo de cred chico",
        },
        hero: {
          uri: "https://img.freepik.com/free-vector/modern-abstract-white-minimal-background_84443-8363.jpg",
          alt: "titulo de cred",
        },
        text: {
          color: "#111111",
        },
      },
    },
    issuer: {
      name: "Test issuer",
      styles: {
        background: {
          color: "#aaffefd5",
        },
        text: {
          color: "#aa4682b4",
        },
        thumbnail: {
          uri: "https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_building-512.png",
          alt: "CreadorCredenciales",
        },
        hero: {
          uri: "https://img.freepik.com/free-vector/modern-abstract-white-minimal-background_84443-8363.jpg",
          alt: "Dominio",
        },
      },
    },
  };
  const universityData = {
    did: did,
    oneTimeUse: false,
    vc: {
      "@context": [
        "https://www.w3.org/2018/credentials/v1",
        "https://w3id.org/security/bbs/v1",
        {
          name: "https://schema.org/Text",
          lastName: "https://schema.org/Text",
          birth: "https://schema.org/Text",
          college: "https://schema.org/Text",
          degree: "https://schema.org/Text",
          coursing: "https://schema.org/Text",
        },
      ],
      id: id,
      type: ["VerifiableCredential", "AlumniCredential"],
      issuer: {
        id: did,
        name: issuer,
      },
      issuanceDate: "01/10/2024",
      credentialSubject: {
        name: name,
        lastName: lastName,
        birth: birth,
        college: value1,
        degree: value2,
        coursing: "Yes",
      },
    },
    outputDescriptor: {
      id: "alumni_credential_output",
      schema: "https://schema.org/EducationalOccupationCredential",
      display: {
        title: {
          text: issuer,
        },
        subtitle: {
          text: "University Card",
        },
        description: {
          text: `University credential provided by ${issuer}`,
        },
        properties: [
          {
            path: ["$.credentialSubject.name"],
            fallback: "-",
            label: "Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.lastName"],
            fallback: "-",
            label: "Last Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.birth"],
            fallback: "-",
            label: "Birth date",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.college"],
            fallback: "-",
            label: "Institution",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.degree"],
            fallback: "-",
            label: "Carreer",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.coursing"],
            fallback: "-",
            label: "Regular student",
            schema: {
              type: "string",
            },
          },
        ],
      },
      styles: {
        background: {
          color: "#00ddee",
        },
        thumbnail: {
          uri: "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png",
          alt: "titulo de cred chico",
        },
        hero: {
          uri: "https://media.istockphoto.com/id/1484747286/photo/abstract-blue-geometric-dynamic-shape-metallic-layers-subtle-background-illustration.webp?b=1&s=170667a&w=0&k=20&c=KlL7nnzNBRbXuwqMvCU56Uy-x8M9zrx2wN0LfNqSWZ0=",
          alt: "titulo de cred",
        },
        text: {
          color: "#ffffff",
        },
      },
    },
    issuer: {
      name: "Test issuer",
      styles: {
        background: {
          color: "#aaffefd5",
        },
        text: {
          color: "#aa4682b4",
        },
        thumbnail: {
          uri: "https://cdn0.iconfinder.com/data/icons/social-circle-3/72/Classmates-256.png",
          alt: "CreadorCredenciales",
        },
        hero: {
          uri: "https://media.istockphoto.com/id/1484747286/photo/abstract-blue-geometric-dynamic-shape-metallic-layers-subtle-background-illustration.webp?b=1&s=170667a&w=0&k=20&c=KlL7nnzNBRbXuwqMvCU56Uy-x8M9zrx2wN0LfNqSWZ0=",
          alt: "Dominio",
        },
      },
    },
  };
  const naturgyData = {
    did: did,
    oneTimeUse: false,
    vc: {
      "@context": [
        "https://www.w3.org/2018/credentials/v1",
        "https://w3id.org/security/bbs/v1",
        {
          name: "https://schema.org/Text",
          lastName: "https://schema.org/Text",
          birth: "https://schema.org/Text",
          address: "https://schema.org/Text",
          nationality: "https://schema.org/Text",
          accountNumber: "https://schema.org/Text",
        },
      ],
      id: id,
      type: ["VerifiableCredential", "ServiceCredential"],
      issuer: {
        id: did,
        name: issuer,
      },
      issuanceDate: "01/10/2024",
      credentialSubject: {
        name: name,
        lastName: lastName,
        birth: birth,
        address: value1,
        nationality: value2,
        accountNumber: value3,
      },
    },
    outputDescriptor: {
      id: "bank_credential_output",
      schema: "bank_credential_output",
      display: {
        title: {
          text: issuer,
        },
        subtitle: {
          text: "Bank Card",
        },
        description: {
          text: `Bank credential provided by ${issuer}`,
        },
        properties: [
          {
            path: ["$.credentialSubject.name"],
            fallback: "-",
            label: "Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.lastName"],
            fallback: "-",
            label: "Last Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.accountNumber"],
            fallback: "-",
            label: "Birth date",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.address"],
            fallback: "-",
            label: "Address",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.nationality"],
            fallback: "-",
            label: "Nationality",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.accountNumber"],
            fallback: "-",
            label: "Birth Place",
            schema: {
              type: "string",
            },
          },
        ],
      },
      styles: {
        background: {
          color: "#ffffff",
        },
        thumbnail: {
          uri: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ3K-fYTyHdMIaXGdbsO2YhIzTd8rNrDCDT9S3BM27mGw0LvE4eOaHXBstVF7HVLbrfUQ&usqp=CAU",
          alt: "titulo de cred chico",
        },
        hero: {
          uri: "https://i.ibb.co/99vmvTr/naturgy-bg2.png",
          alt: "titulo de cred",
        },
        text: {
          color: "#ffffff",
        },
      },
    },
    issuer: {
      name: "Test issuer",
      styles: {
        background: {
          color: "#aaffefd5",
        },
        text: {
          color: "#ffffff",
        },
        thumbnail: {
          uri: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ3K-fYTyHdMIaXGdbsO2YhIzTd8rNrDCDT9S3BM27mGw0LvE4eOaHXBstVF7HVLbrfUQ&usqp=CAU",
          alt: "CreadorCredenciales",
        },
        hero: {
          uri: "https://i.ibb.co/99vmvTr/naturgy-bg2.png",
          alt: "Dominio",
        },
      },
    },
  };
  const santanderData = {
    did: did,
    oneTimeUse: false,
    vc: {
      "@context": [
        "https://www.w3.org/2018/credentials/v1",
        "https://w3id.org/security/bbs/v1",
        {
          name: "https://schema.org/Text",
          lastName: "https://schema.org/Text",
          birth: "https://schema.org/Text",
          address: "https://schema.org/Text",
          nationality: "https://schema.org/Text",
          accountNumber: "https://schema.org/Text",
        },
      ],
      id: id,
      type: ["VerifiableCredential", "BankCredential"],
      issuer: {
        id: did,
        name: "Santander Bank",
      },
      issuanceDate: "01/10/2024",
      credentialSubject: {
        name: name,
        lastName: lastName,
        birth: birth,
        address: value1,
        nationality: value2,
        accountNumber: value3,
      },
    },
    outputDescriptor: {
      id: "bank_credential_output",
      schema: "bank_credential_output",
      display: {
        title: {
          text: "Santander Bank",
        },
        subtitle: {
          text: "Bank Card",
        },
        description: {
          text: `Bank credential provided by Santander`,
        },
        properties: [
          {
            path: ["$.credentialSubject.name"],
            fallback: "-",
            label: "Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.lastName"],
            fallback: "-",
            label: "Last Name",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.birth"],
            fallback: "-",
            label: "Birth date",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.address"],
            fallback: "-",
            label: "Address",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.nationality"],
            fallback: "-",
            label: "Nationality",
            schema: {
              type: "string",
            },
          },
          {
            path: ["$.credentialSubject.accountNumber"],
            fallback: "-",
            label: "Birth Place",
            schema: {
              type: "string",
            },
          },
        ],
      },
      styles: {
        background: {
          color: "#ffffff",
        },
        thumbnail: {
          uri: "https://i.ibb.co/cJ2z3vS/bcda8a3eaa78befafba68b851b2cdfc0-removebg-preview.png",
          alt: "titulo de cred chico",
        },
        hero: {
          uri: "https://i.ibb.co/DMN1N5H/santander-bg.png",
          alt: "titulo de cred",
        },
        text: {
          color: "#ffffff",
        },
      },
    },
    issuer: {
      name: "Test issuer",
      styles: {
        background: {
          color: "#ffffff",
        },
        text: {
          color: "#ffffff",
        },
        thumbnail: {
          uri: "https://i.ibb.co/cJ2z3vS/bcda8a3eaa78befafba68b851b2cdfc0-removebg-preview.png",
          alt: "CreadorCredenciales",
        },
        hero: {
          uri: "https://i.ibb.co/DMN1N5H/santander-bg.png",
          alt: "Dominio",
        },
      },
    },
  };
  if (vcType === "citizen") {
    data = citizenData;
    return data;
  } else if (vcType === "university") {
    return (data = universityData);
  } else if (vcType === "naturgy") {
    return (data = naturgyData);
  } else if (vcType === "santander") {
    return (data = santanderData);
  }
  return data;
};

export default invitation;
