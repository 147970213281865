import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import "./RenderCredential.css";
import Credential from "../Credential";

const RenderCredentials = () => {
  const context = useContext(DidContext);

  return (
    <div className="Render">
      <h3>Credentials</h3>
      <div className="renderVc-container">
        {context.vcsById.length > 0 &&
          context.vcsById.map((credential) => (
            <Credential
              credential={credential}
              key={credential.vc.data.data.id}
            />
          ))}
      </div>
    </div>
  );
};

export default RenderCredentials;
