// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal {
  width: 100%;
  height: calc(100vh - 2.8vw);
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s;
  will-change: opacity;
  z-index: 15;
}

.Modal.hide {
  opacity: 0;
}

.Modal.show {
  opacity: 1;
}

.Modal.darkMode {
  background: #101010bd;
  box-shadow: inset 0 0 15px 1px rgba(0, 0, 0, 0.4);
}

.Modal.lightMode {
  background: #f9f9f9cd;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.modal-container {
  min-height: 20vw;
  margin: 3vw 0 0;
  padding: 1vw 3vw 2vw;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.Modal.darkMode .modal-container {
  border: 2px solid #444444;
  background: #222222;
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.4);
}

.Modal.lightMode .modal-container {
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2BAA2B;EAC3B,MAAM;EACN,OAAO;EACP,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,iDAAiD;AACnD;;AAEA;EACE,qBAAqB;EACrB,+CAA+C;AACjD;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,2CAA2C;EAC3C,yBAAyB;AAC3B","sourcesContent":[".Modal {\n  width: 100%;\n  height: calc(100vh - 2.8vw);\n  top: 0;\n  left: 0;\n  opacity: 0;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  transition: 0.3s;\n  will-change: opacity;\n  z-index: 15;\n}\n\n.Modal.hide {\n  opacity: 0;\n}\n\n.Modal.show {\n  opacity: 1;\n}\n\n.Modal.darkMode {\n  background: #101010bd;\n  box-shadow: inset 0 0 15px 1px rgba(0, 0, 0, 0.4);\n}\n\n.Modal.lightMode {\n  background: #f9f9f9cd;\n  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n.modal-container {\n  min-height: 20vw;\n  margin: 3vw 0 0;\n  padding: 1vw 3vw 2vw;\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  overflow: auto;\n}\n\n.Modal.darkMode .modal-container {\n  border: 2px solid #444444;\n  background: #222222;\n  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.4);\n}\n\n.Modal.lightMode .modal-container {\n  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);\n  background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
