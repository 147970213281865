// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout {
  width: 85%;
  max-height: calc(100vh - 5vw);
  min-height: calc(100vh - 5vw);
  top: 5vw;
  left: 15%;
  position: absolute;
  overflow: auto;
}

.container {
  width: 95%;
  margin: 0 auto;
  padding: 0 0 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,6BAA6B;EAC7B,6BAA6B;EAC7B,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".Layout {\n  width: 85%;\n  max-height: calc(100vh - 5vw);\n  min-height: calc(100vh - 5vw);\n  top: 5vw;\n  left: 15%;\n  position: absolute;\n  overflow: auto;\n}\n\n.container {\n  width: 95%;\n  margin: 0 auto;\n  padding: 0 0 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
