// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.renderVc-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 1vw 0;
}

.renderVc {
  cursor: pointer;
  width: 30%;
  margin: 1vw 1.5%;
  padding: 1.5vw 0;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.renderVc.active {
  background: #ebebeb70;
}
.renderVc.inactive {
  background: #7a7a7a70;
}
.renderVc h6 {
  width: 80%;
  margin: 0 0 2vw;
  font-size: 1.1vw;
  font-weight: 500;
}
.renderVc div {
  width: 80%;
}

.renderVc div p {
  width: 100%;
  margin: 0 0 0.3vw;
  font-size: 0.9vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/RenderCredentials/RenderCredential.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;;EAEnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,yCAAyC;AAC3C;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".renderVc-container {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n\n  padding: 1vw 0;\n}\n\n.renderVc {\n  cursor: pointer;\n  width: 30%;\n  margin: 1vw 1.5%;\n  padding: 1.5vw 0;\n  border-radius: 8px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);\n}\n.renderVc.active {\n  background: #ebebeb70;\n}\n.renderVc.inactive {\n  background: #7a7a7a70;\n}\n.renderVc h6 {\n  width: 80%;\n  margin: 0 0 2vw;\n  font-size: 1.1vw;\n  font-weight: 500;\n}\n.renderVc div {\n  width: 80%;\n}\n\n.renderVc div p {\n  width: 100%;\n  margin: 0 0 0.3vw;\n  font-size: 0.9vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
