import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { DidContext } from "./context";
import Header from "./components/Header";
import Layout from "./components/Layout";
import StateBar from "./components/StateBar";
import Credentials from "./pages/Credentials";
import DID from "./pages/DID";
import Invitation from "./pages/Invitation";

function App() {
  const context = useContext(DidContext);

  return (
    <div className={`App ${context.theme}`}>
      <HashRouter>
        <Header />
        <StateBar />
        <Layout>
          <Routes>
            <Route path="/" element={<DID />} />
            <Route path="/did" element={<DID />} />
            <Route path="/invitation" element={<Invitation />} />
            <Route path="/credentials" element={<Credentials />} />
          </Routes>
        </Layout>
      </HashRouter>
    </div>
  );
}

export default App;
