// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.get-document-container {
  width: 50vw;
  position: relative;
}

.get-document-close {
  width: 2.5vw;
  height: 2.5vw;
  top: 1.2vw;
  right: 0;
  position: absolute;
  cursor: pointer;
  opacity: 0.3;
  will-change: opacity;
  transition: 0.3s;
}

.get-document-container h2 {
  margin-bottom: 2vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/GetDocument/GetDocument.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,QAAQ;EACR,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".get-document-container {\n  width: 50vw;\n  position: relative;\n}\n\n.get-document-close {\n  width: 2.5vw;\n  height: 2.5vw;\n  top: 1.2vw;\n  right: 0;\n  position: absolute;\n  cursor: pointer;\n  opacity: 0.3;\n  will-change: opacity;\n  transition: 0.3s;\n}\n\n.get-document-container h2 {\n  margin-bottom: 2vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
