import { useContext, useRef, useState } from "react";
import { DidContext } from "../../context";
import {
  CheckCircleIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import "./CopyToClipboard.css";

const CopyToClipboard = ({ inputValue, textarea, disabled }) => {
  const context = useContext(DidContext);
  const [copySuccess, setCopySuccess] = useState(false);
  const inputRef = useRef(null);
  const showValue = inputValue;

  function copy(e) {
    navigator.clipboard.writeText(inputValue);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  }

  return (
    <div className={`CopyToClipboard ${context.theme}`}>
      {textarea ? (
        <textarea
          className={`input-to-copy textarea`}
          ref={inputRef}
          value={showValue}
          disabled={disabled}
        />
      ) : (
        <input
          className={`input-to-copy input`}
          ref={inputRef}
          value={showValue}
          disabled={disabled}
        />
      )}

      <button
        className={`copy-button `}
        disabled={inputValue ? false : true}
        onClick={() => copy()}
      >
        {copySuccess ? (
          <CheckCircleIcon className="success-icon" />
        ) : (
          <DocumentDuplicateIcon className="copy-icon" />
        )}
      </button>
    </div>
  );
};

export default CopyToClipboard;
