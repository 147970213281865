import { useContext } from "react";
import { DidContext } from "../../context";
import { NavLink } from "react-router-dom";
import logoLight from "../../assets/extrimian.png";
import logoDark from "../../assets/extrimian-white.png";
import "./Header.css";

const Header = () => {
  const context = useContext(DidContext);
  return (
    <header className={`Header ${context.theme}`}>
      <div className="nav">
        <NavLink
          style={{
            textDecoration: "none",
          }}
          to="/did"
        >
          My DIDs
        </NavLink>
      </div>
      <div className="nav">
        <NavLink
          style={{
            textDecoration: "none",
          }}
          to="/invitation"
        >
          Invitation
        </NavLink>
      </div>
      <div className="logo-container">
        <img src={context.darkMode ? logoDark : logoLight} alt="Logo" />
      </div>
    </header>
  );
};

export default Header;
