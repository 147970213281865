import { useContext } from "react";
import { DidContext } from "../../context";
import { XCircleIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "../CopyToClipboard";
import "./GetDocument.css";

const GetDocument = () => {
  const context = useContext(DidContext);
  return (
    <div className={`get-document-container`}>
      <h2>Did Document</h2>
      <p>
        Reference: <br /> <strong>{context.didDocument.reference}</strong>
      </p>
      <XCircleIcon
        className="create-did-close"
        onClick={() => context.setOpenDocument(false)}
      />
      <CopyToClipboard
        inputValue={context.didDocument.document}
        textarea={true}
        disabled={true}
      />
    </div>
  );
};

export default GetDocument;
