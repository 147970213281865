import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Button from "../Button";
import Loader from "../Loader";
import CopyToClipboard from "../CopyToClipboard";
import "./CreateDID.css";

const CreateDID = () => {
  const context = useContext(DidContext);
  const [creationRef, setCreationRef] = useState("");
  const [creationRole, setCreationRole] = useState("issuers");
  const [vcType, setVcType] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [newDid, setNewDid] = useState("");
  const [succsesfulCreation, setSuccsesfulCreation] = useState(false);

  // Close Modal
  const closeModal = () => {
    if (newDid !== "" || context.createNewDid.did === "failed") {
      setSuccsesfulCreation(false);
      setMessage("");
      setNewDid("");
      context.setCreateNewDid({
        did: "",
        processing: false,
        message: "",
      });
      context.setOnCreate(false);
    }
    context.setOpenCreate(false);
  };
  // Get reference
  const handleInputChange = (event) => {
    setCreationRef(event.target.value);
  };

  // Form validation
  const handleCreate = () => {
    const issuerRefValues = context.dids.holders
      .map((did) => did.ref)
      .includes(creationRef);
    const holderRefValues = context.dids.holders
      .map((did) => did.ref)
      .includes(creationRef);
    if (creationRef === "") {
      setMessage("Insert reference value");
    } else if (issuerRefValues || holderRefValues) {
      setMessage("Reference already exists, choose another one.");
    } else if (creationRole === "issuers" && !vcType) {
      setMessage("Select Verifiable Credential Type");
    } else {
      setMessage("Creating DID");
      setLoading(true);
      context.setOnCreate(true);
      context.createDid({
        ref: creationRef,
        role: creationRole,
        vcType: vcType,
      });
    }
  };

  // Check succsesful creation
  useEffect(() => {
    if (
      context.createNewDid.did !== "" &&
      context.createNewDid.did !== "failed"
    ) {
      setSuccsesfulCreation(true);
      setMessage("DID created successfully");
      setNewDid(context.createNewDid.did);
      setLoading(false);
    } else if (context.createNewDid.did === "failed") {
      setSuccsesfulCreation(false);
      setMessage(`${context.createNewDid.message}. Try again. `);
      context.setOnCreate(false);
    }
  }, [context.createNewDid.did, context.createNewDid.message]);

  // VC Buttons
  const renderVcSelect = () => {
    return (
      <div className="create-did-container-buttons">
        <label htmlFor="">Verifiable Credential Type:</label>

        <p
          onClick={() => setVcType("citizen")}
          className={`create-did-buttons-button ${
            vcType === "citizen" ? "active" : "inactive"
          }`}
        >
          Citizen
        </p>
        <p
          onClick={() => setVcType("university")}
          className={`create-did-buttons-button ${
            vcType === "university" ? "active" : "inactive"
          }`}
        >
          University
        </p>
        <p
          onClick={() => setVcType("bank")}
          className={`create-did-buttons-button ${
            vcType === "bank" ? "active" : "inactive"
          }`}
        >
          Bank
        </p>
      </div>
    );
  };

  const renderResponseIcon = () => {
    return succsesfulCreation ? (
      <div className="response-container-icon">
        <CheckCircleIcon width={"4vw"} style={{ color: "#0abb89" }} />
      </div>
    ) : (
      <div className="response-container-icon">
        <ExclamationCircleIcon width={"4vw"} style={{ color: "#bb330a" }} />
      </div>
    );
  };
  return (
    <div className={`create-did-container ${context.theme}`}>
      <h2>Create new DID</h2>
      <XCircleIcon className="create-did-close" onClick={() => closeModal()} />
      {!context.onCreate ? (
        <>
          <div className="create-did-container-buttons">
            <p
              onClick={() => setCreationRole("issuers")}
              className={`create-did-buttons-button ${
                creationRole === "issuers" ? "active" : "inactive"
              }`}
            >
              Issuer
            </p>
            <p
              onClick={() => setCreationRole("holders")}
              className={`create-did-buttons-button ${
                creationRole === "holders" ? "active" : "inactive"
              }`}
            >
              Holder
            </p>
          </div>
          <div className="create-did-subcontainer">
            <div className="create-did-container-inputs">
              <label htmlFor="">Reference:</label>
              <input type="text" onChange={handleInputChange} maxLength={30} />
              <label htmlFor="">Websocket:</label>
              <input
                type="text"
                value={"https://sandbox-ssi-ws.extrimian.com"}
                disabled
              />
            </div>
            {creationRole === "issuers" && renderVcSelect()}
          </div>

          <Button
            onClick={handleCreate}
            disabled={context.onCreate}
            text={"Create DID"}
          />
          <p className="create-did-message">{message}</p>
        </>
      ) : (
        <div className="response-container">
          {loading ? (
            <div className="response-container-icon">
              <Loader type={"circular"} loaderWidth={"4vw"} />
            </div>
          ) : (
            renderResponseIcon()
          )}
          <p className="response-container-message">{message}</p>
          <p className="response-container-labels">Reference:</p>
          <CopyToClipboard
            inputValue={creationRef}
            textarea={false}
            disabled={true}
          />
          <p className="response-container-labels">New DID:</p>
          <CopyToClipboard
            inputValue={newDid}
            textarea={false}
            disabled={true}
          />
        </div>
      )}
    </div>
  );
};

export default CreateDID;
