import { useContext, useEffect, useState } from "react";
import { DidContext } from "../../context";
import Button from "../Button";
import QRCode from "react-qr-code";
import quarkIdLogo from "../../assets/logo-quark-id.png";
import "./InvitationForm.css";
import Credential from "../Credential";

const InvitationForm = () => {
  const context = useContext(DidContext);
  const [placeholders, setPlaceholders] = useState({});
  const [cardIcon, setcardIcon] = useState(undefined);
  const [cardType, setCardType] = useState("cardTypeNone");
  const [validation, setValidation] = useState(false);
  const [credentialsValidated, setCredentialsValidated] = useState(false);

  // Load form type
  useEffect(() => {
    if (context.issuerDid.vcType === "santander") {
      setValidation(true);
      setCredentialsValidated(false);
    } else {
      setValidation(false);
      setCredentialsValidated(true);
    }
    setcardIcon(context.issuerDid.iconUrl);
    switch (context.issuerDid.vcType) {
      default:
        setCardType("cardTypeNone");
        setPlaceholders({
          name: "",
          lastName: "",
          birth: "",
          value1: "",
          value2: "",
          value3: "",
        });
        break;
      case "citizen":
        setCardType("citizen");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Address",
          value2: "Nationality",
          value3: "Birth Place",
        });
        break;
      case "university":
        setCardType("university");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Institution",
          value2: "Career",
          value3: "ID Number",
        });
        break;
      case "naturgy":
        setCardType("naturgy");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Address",
          value2: "Nationality",
          value3: "Account Number",
        });
        break;
      case "santander":
        setCardType("santander");

        setPlaceholders({
          name: "Name",
          lastName: "Last Name",
          birth: "Birth Date",
          value1: "Address",
          value2: "Nationality",
          value3: "Account Number",
        });
    }
  }, [context.issuerDid.vcType]);

  useEffect(() => {
    if (context.verifiableDid.did) {
      const governmentCreated = findValidationCredentials("Government");
      const naturgyCreated = findValidationCredentials("Naturgy");
      if (governmentCreated && naturgyCreated) {
        const governmentCredential = getValidationCredential("Government");
        const naturgyCredential = getValidationCredential("Naturgy");
        if (governmentCredential.verified && naturgyCredential.verified) {
          setCredentialsValidated(true);
        }
      }
    }
  }, [context.vcsById]);

  // Santander validation
  const findValidationCredentials = (value) => {
    return context.vcsById
      .map((vc) => vc.vc.data.data.issuer.name)
      .includes(value);
  };

  const getValidationCredential = (value) => {
    const validationCredential = context.vcsById.find(
      (credential) => credential.vc.data.data.issuer.name === value
    );
    return validationCredential;
  };

  const renderValidation = (value) => {
    const renderCredential = getValidationCredential(value);
    return (
      <Credential
        credential={renderCredential}
        key={renderCredential.vc.data.data.id}
      />
    );
  };

  // Input listener
  const handleInputChange = (event) => {
    context.setFormData({
      ...context.formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="get-invitation-container">
      {validation && (
        <div className="invitation-validation-container">
          <p>
            To get this credential you must get and verify your Citizen and
            Naturgy credentials.{" "}
          </p>
          <div className="invitation-validation-credentials-container">
            {findValidationCredentials("Government") ? (
              renderValidation("Government")
            ) : (
              <div className="invitation-validation-credential">
                Get Citizen credential
              </div>
            )}

            {findValidationCredentials("Naturgy") ? (
              renderValidation("Naturgy")
            ) : (
              <div className="invitation-validation-credential">
                Get Naturgy credential
              </div>
            )}
          </div>
        </div>
      )}
      <form
        action=""
        onSubmit={(e) => e.preventDefault()}
        className={`invitation-form ${context.theme}`}
      >
        <input
          type="text"
          name="issuer"
          value={
            context.formData.issuer !== undefined ? context.formData.issuer : ""
          }
          disabled
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="name"
          disabled={!credentialsValidated}
          placeholder={context.formData.issuer !== "" ? placeholders.name : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          disabled={!credentialsValidated}
          placeholder={
            context.formData.issuer !== "" ? placeholders.lastName : ""
          }
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="birth"
          disabled={!credentialsValidated}
          placeholder={context.formData.issuer !== "" ? placeholders.birth : ""}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value1"
          disabled={!credentialsValidated}
          placeholder={
            context.formData.issuer !== "" ? placeholders.value1 : ""
          }
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value2"
          disabled={!credentialsValidated}
          placeholder={
            context.formData.issuer !== "" ? placeholders.value2 : ""
          }
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="value3"
          disabled={!credentialsValidated}
          placeholder={
            context.formData.issuer !== "" ? placeholders.value3 : ""
          }
          onChange={handleInputChange}
        />
        <Button
          onClick={context.generateInvitation}
          text={"Generate Invitation"}
          disabled={context.formData.issuer ? !credentialsValidated : true}
          color={"green"}
        />
        <p className="invitation-error">{context.invitationError}</p>
      </form>
      <div className={`presentation-container ${cardType}`}>
        {" "}
        {cardIcon ? (
          <div className={`presentation-logo ${cardType}`}>
            <img src={cardIcon} alt="logo" />{" "}
          </div>
        ) : (
          <></>
        )}
        <div className="presentation-data">
          <h5>{context.formData.issuer}</h5>
          <p>
            {placeholders.name} <span>{context.formData.name}</span>
          </p>
          <p>
            {placeholders.lastName} <span>{context.formData.lastName}</span>
          </p>
          <p>
            {placeholders.birth} <span>{context.formData.birth}</span>
          </p>
          <p>
            {placeholders.value1} <span>{context.formData.value1}</span>
          </p>
          <p>
            {placeholders.value2} <span>{context.formData.value2}</span>
          </p>
          <p>
            {placeholders.value3} <span>{context.formData.value3}</span>
          </p>
        </div>
      </div>
      <div className={`qr-container ${context.showQr ? "show" : "hide"}`}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={context.oobContentData}
          viewBox={`0 0 256 256`}
        />
        <p className="qrcode-text">
          Scan with{" "}
          <a href="https://quarkid.org/" target="_blank" rel="noreferrer">
            <img src={quarkIdLogo} alt="" />
          </a>
        </p>
      </div>
    </div>
  );
};

export default InvitationForm;
